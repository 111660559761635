<template>
  <div class="LookupPage">
    <Header :broadcast="broadcast" :currentBrand="currentBrand" />
    <b-col>
      <h2>{{ txtTitle }}</h2>
    </b-col>

    <section class="container text-center">
      <b-form
        class="text-center mb-4 "
        @submit.prevent="onSubmit"
      >
        <input
          type="text"
          class="col-8 col-sm-4 mb-4 form-control"
          maxLength="9"
          id="unclockCode"
          style="text-align: center; letter-spacing: 3px; width: auto; display: inline-block;"
          v-model="shareId"
          ref="shareId"
        />
        <br />
        <b-button variant="primary" :disabled="disabled" type="submit">
          {{ txtSubmit }}
        </b-button>
      </b-form>
      <p
        class="memoriam-tv--text-danger memoriam-tv--loading"
        v-if="errored"
        v-html="txtConnectionError"
      ></p>
    </section>
    <h3
      class="memoriam-tv--text-danger"
      v-if="gone"
      v-html="txtGone"
    ></h3>
    <transition name="slide">
      <section v-show="gone">
        <b-row class="justify-content-center">
          <b-col cols="10" md="8" xl="6" class="text-left">
            <p>Dit kan verschillende oorzaken hebben:</p>
            <dl>
              <dt>De opname is verwijderd</dt>
              <dd>
                Opnames blijven normaal 1 maand beschikbaar op ons platform.
              </dd>
              <dt>De uitzending is geannuleerd.</dt>
              <dd>
                Er is besloten om geen uitzending plaats te laten vinden.
              </dd>
            </dl>
            <p v-if="memoriamDomain()">
              Neem voor meer informatie contact op met de uitvaartverzorger.
            </p>
          </b-col>
        </b-row>
      </section>
    </transition>
    <h3
      class="memoriam-tv--text-danger"
      v-if="invalidCode"
      v-html="txtError"
    ></h3>
    <transition name="slide">
      <section v-show="invalidCode">
        <b-row class="justify-content-center">
          <b-col cols="10" md="8" xl="6" class="text-left">
            <p>Dit kan verschillende oorzaken hebben:</p>
            <dl>
              <dt>De code die u heeft gekregen werkt niet (meer).</dt>
              <dd v-if="memoriamDomain()">
                Dat kan gebeuren. Vraag de uitvaartverzorger of betrokkene om een
                correcte link / code op te sturen.
              </dd>
              <dd v-else>
                Dat kan gebeuren. Wellicht is er een nieuwe link beschikbaar.
              </dd>
              <dt>U heeft de code niet correct overgetypt.</dt>
              <dd>
                Probeer het nog een keer.<br/>
                <ul>
                  <li>
                    De
                    <code>B</code> <em>(van Bernhard)</em> moet een
                    <code>8</code> <em>(acht)</em> zijn.
                  </li>
                  <li>
                    De
                    <code>I</code> <em>(van Isaak)</em> of
                    <code>L</code> <em>(van Leon)</em> moet een
                    <code>1</code> <em>(een)</em> zijn.
                  </li>
                  <li>
                    De
                    <code>O</code> <em>(van Oscar)</em> moet een
                    <code>0</code> <em>(nul)</em> zijn.
                  </li>
                  <li>
                    De
                    <code>U</code> <em>(van Utrecht)</em> zou niet mogen voorkomen.
                  </li>
                </ul>
              </dd>
              <dt>Als dat niet helpt:</dt>
              <dd>
                <ul>
                  <li>Vraag het eens aan iemand anders.</li>
                  <li>
                    Heeft de persoon waarvan u de link heeft gekregen misschien
                    een betere versie?
                  </li>
                </ul>
              </dd>
              <dd />
              <dt>Als laatste redmiddel:</dt>
              <dd>
                Vul <a href="https://www.memoriam.tv/contact/">dit formulier</a>
                in. Helaas kunnen we geen snelle reactie garanderen.
              </dd>
            </dl>
          </b-col>
        </b-row>
      </section>
    </transition>
    <hr/>
    <aside class="">
      <b-row class="justify-content-center">
        <b-col cols="10" md="8" xl="6" class="text-left">
          <dl>
            <dt>Heeft u een link gekregen?</dt>
            <dd>Voer dan hierboven de cijfers en/of letters in die staan achter <code>https://{{ host }}/</code></dd>
            <dd>
              Bijvoorbeeld:
              in de link <code>https://{{ host }}/ABCD-EFGH</code> is de code <nobr><strong><code>ABCD-EFGH</code></strong></nobr>
            </dd>
            <dt v-if="memoriamDomain()">Heeft u een code gekregen van 6 karakters?</dt>
            <dd v-if="memoriamDomain()">
              Dan zit u op de verkeerde pagina. U dient naar de pagina
              van de uitvaartverzorger te gaan.
            </dd>
          </dl>
        </b-col>
      </b-row>
    </aside>
  </div>
</template>

<script>
import currentBrandMixin from '../mixins/currentBrandMixin';
import domainChecker from '../mixins/domainChecker';
import Header from '../components/Header.vue';
import Broadcast from '../models/Broadcast';

export default {
  mixins: [currentBrandMixin, domainChecker],
  components: {
    Header,
  },
  data() {
    const { host } = window.location;
    return {
      host,
      loading: true,
      errored: false,
      errors: null,
      shareId: '',
      disabled: true,
      landingPageUrl: false,
      invalidCode: false,
      gone: false,
      data: null,
      broadcast: { name: '' },
    };
  },
  props: {
    txtTitle: {
      type: String,
      required: false,
      default: 'Voer hier uw code in:',
    },
    txtSubmit: { type: String, required: false, default: 'Verzend' },
    txtUnlocked: {
      type: String,
      required: false,
      default: 'Code is correct.',
    },
    txtLink: {
      type: String,
      required: false,
      default: 'Bekijk uitzending',
    },
    txtError: {
      type: String,
      required: false,
      default:
        'De ingevoerde code is niet correct. <br/>Probeer een andere code.',
    },
    txtGone: {
      type: String,
      required: false,
      default:
        'De ingevoerde code is niet meer geldig.',
    },
    txtConnectionError: {
      type: String,
      required: false,
      default:
        'Contact met de server is mislukt. <br/>Probeer het later opnieuw.',
    },
    mode: { type: String, required: false },
  },
  methods: {
    onSubmit() {
      Broadcast.where({ shareId: this.shareId.replace(/-/g, '') }).all().then((response) => {
        // eslint-disable-next-line prefer-destructuring
        this.broadcast = response.data[0];
        this.passthrough = response.data.encoderPassthrough;
        this.errored = false;
        this.errors = null;
      })
        .catch((error) => {
          if (error.response?.status === 410) {
            // eslint-disable-next-line no-console
            console.error('Gone', error);
            this.gone = true;
          } else if (error.response?.status === 404) {
            this.invalidCode = true;
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
            this.errored = true;
          }
          this.errors = error;
        })
        .finally(() => {
          this.loading = false;
          this.requestPending = false;
        });
    },
    focusInput() {
      this.$refs.shareId.focus();
    },
  },
  watch: {
    'broadcast.shareId': function broadcastUlid(newVal) {
      if (newVal && newVal !== '') {
        this.$router.push({ name: 'PlayerPage', params: { shareId: this.broadcast.shareId } });
      }
    },
    'errors.shareId': function errorOnshareId(newVal) {
      if (newVal) {
        this.invalidCode = true;
      }
    },
    shareId(newValue) {
      if (newValue) {
        this.shareId = newValue
          .toUpperCase()
          .replace(/[^0-9AC-HJKMNP-TV-Z_-]/g, '');
        this.disabled = this.shareId.length < 8;
        if (this.invalidCode) {
          this.invalidCode = this.shareId.length < 2;
        }
        if (this.gone) {
          this.gone = this.shareId.length < 2;
        }
      }
    },
    disabled(newValue) {
      if (!newValue) {
        this.broadcast.shareId = this.shareId;
      }
    },
  },
  mounted() {
    this.focusInput();
  },
};
</script>

<style scoped>
  .slide-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  .slide-leave-active {
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  .slide-enter-to, .slide-leave {
    max-height: 300px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  .header {
    text-align: center;
  }
  input.form-control {
    text-align: center;
    letter-spacing: 3px;
    width: auto;
    display: inline-block;
  }
  .memoriam-tv--text-danger {
    color: #D40758;
  }
  /* .memoriam-tv--loading {

  } */
</style>
