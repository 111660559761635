import { attr } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from './ApplicationRecord';

const Redirect = ApplicationRecord.extend({
  static: {
    jsonapiType: 'redirects',
  },
  attrs: {
    id: attr({ persist: false }),
    ulid: attr({ persist: false }),
    name: attr({ persist: false }),
    startAt: attr({ persist: false }),
    unlockCode: attr({ persist: true }),
    shareId: attr({ persist: false }),
    encoderPassthrough: attr({ persist: false }),
    eventPassthrough: attr({ persist: false }),
  },
});

export default Redirect;
