const domainChecker = {
  methods: {
    memoriamDomain() {
      const { host } = window.location;
      return host === 'start-staging.memoriam.tv'
          || host === 'start.memoriam.tv'
          || host === 'localhost:5000';
    },
    attendDomain() {
      return !this.memoriamDomain();
    },
  },
};
export default domainChecker;
