import { SpraypaintBase } from 'spraypaint/dist/spraypaint';

const ApplicationRecord = SpraypaintBase.extend({
  static: {
    baseUrl: process.env.VUE_APP_MEMORIAM_TV_API_ENDPOINT,
    apiNamespace: process.env.VUE_APP_MEMORIAM_TV_API_NAMESPACE || '/v1',
    clientApplication: 'start-app',
  },
});

export default ApplicationRecord;
