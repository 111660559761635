<template>
  <div class="redirect">
    <Header :broadcast="redirect" :currentBrand="currentBrand" />
    <b-container v-if="errored" class="mt-4">
      <ErrorMessage :error="errors" title="Ongeldige URL"/>
    </b-container>
    <b-container v-else class="mt-4">
      <h1>{{ redirect.name }}</h1>
      <section class="container text-center">
        <h5>{{ txtTitle }}</h5>
        <b-form
          class="text-center mb-4 "
          @submit.prevent="onSubmit"
        >
          <input
            type="text"
            class="col-8 col-sm-4 mb-4 form-control"
            maxLength="6"
            id="unclockCode"
            style="text-align: center; letter-spacing: 3px; width: auto; display: inline-block;"
            v-model="unlockCode"
            ref="unlockCode"
          />
          <br />
          <b-button variant="primary" :disabled="disabled" type="submit">
            {{ txtSubmit }}
          </b-button>
          <p
            class="memoriam-tv--text-danger"
            v-if="invalidCode"
            v-html="txtError"
          ></p>
          <p
            class="memoriam-tv--text-danger memoriam-tv--loading"
            v-if="errored"
            v-html="txtConnectionError"
          ></p>
        </b-form>
      </section>
    </b-container>

  </div>
</template>

<script>
import currentBrandMixin from '../mixins/currentBrandMixin';
import Header from '../components/Header.vue';
import Redirect from '../models/Redirect';
import ErrorMessage from './ErrorMessage.vue';

export default {
  mixins: [currentBrandMixin],
  components: {
    Header,
    ErrorMessage,
  },
  data() {
    return {
      loading: true,
      // this is a dummy broadcast for currentBrandMixin
      broadcast: { encoderPassthrough: {}, eventPassthrough: {} },
      errored: false,
      errors: null,
      unlockCode: '',
      disabled: true,
      landingPageUrl: false,
      invalidCode: false,
      data: null,
      redirectId: this.discoverRedirectId(),
      redirect: { name: '' },
    };
  },
  props: {
    txtTitle: {
      type: String,
      required: false,
      default: 'Voer hier uw verkregen code in:',
    },
    txtSubmit: { type: String, required: false, default: 'Verzend' },
    txtUnlocked: {
      type: String,
      required: false,
      default: 'Code is correct.',
    },
    txtLink: {
      type: String,
      required: false,
      default: 'Bekijk uitzending',
    },
    txtError: {
      type: String,
      required: false,
      default:
        'De ingevoerde code is niet correct. <br/>Probeer het nog een keer.',
    },
    txtConnectionError: {
      type: String,
      required: false,
      default:
        'Contact met de server is mislukt. <br/>Probeer het later opnieuw.',
    },
    mode: { type: String, required: false },
  },
  methods: {
    discoverRedirectId() {
      return this.$route.params.redirectId;
    },
    onSubmit() {
      this.redirect.save().then((success) => {
        if (success) {
          this.errors = null;
        } else {
          this.errors = this.redirect.errors;
        }
      })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.errored = true;
          this.errors = error;
        })
        .finally(() => {
          this.loading = false;
          this.requestPending = false;
        });
    },
    focusInput() {
      this.$refs.unlockCode.focus();
    },
  },
  watch: {
    'redirect.shareId': function redirectUlid(newVal) {
      if (newVal && newVal !== '') {
        this.$router.push({ name: 'PlayerPage', params: { shareId: this.redirect.shareId } });
      }
    },
    'redirect.eventPassthrough': function eventPassthrough(newVal) {
      this.broadcast.eventPassthrough = newVal;
    },
    'redirect.encoderPassthrough': function encoderPassthrough(newVal) {
      this.broadcast.encoderPassthrough = newVal;
    },
    'errors.unlockCode': function errorOnUnlockCode(newVal) {
      if (newVal) {
        this.invalidCode = true;
      }
    },
    unlockCode(newValue) {
      if (newValue) {
        this.unlockCode = newValue
          .toUpperCase()
          .replace(/[^0-9AC-HJKMNP-TV-Z_]/g, '');
        this.disabled = this.unlockCode.length < 6;
        if (this.invalidCode) {
          this.invalidCode = this.unlockCode.length < 2;
        }
      }
    },
    disabled(newValue) {
      if (!newValue) {
        this.redirect.unlockCode = this.unlockCode;
      }
    },
  },
  mounted() {
    this.focusInput();
    Redirect.find(this.redirectId).then((response) => {
      this.redirect = response.data;
      this.passthrough = response.data.encoderPassthrough;
      this.errored = false;
      this.errors = null;
    })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.errored = true;
        this.errors = error;
      })
      .finally(() => {
        this.loading = false;
        this.requestPending = false;
      });
  },
};
</script>

<style>

input.form-control {
  text-align: center;
  letter-spacing: 3px;
  width: auto;
  display: inline-block;
}

</style>
