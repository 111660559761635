// TODO: Enforce precedence over encoder settings. e.g.: let event values overwrite encoder values

const currentBrandMixin = {
  methods: {
    setCustomCss(newValue, oldValue) {
      const oldCss = oldValue?.css;
      const newCss = newValue?.css;
      if (JSON.stringify(newCss) !== JSON.stringify(oldCss)) {
        const element = document.getElementById('dynamic_style');
        if (typeof element === 'undefined' || element == null) {
          const newElement = document.createElement('style');
          newElement.setAttribute('id', 'dynamic_style');
          document.head.appendChild(newElement);
          newElement.appendChild(document.createTextNode(newCss));
        } else {
          element.replaceChild(document.createTextNode(newCss), element.firstChild);
        }
      }
    },
  },
  data() {
    return {
      currentBrand: null,
      currentBrandReference: null,
    };
  },
  watch: {
    currentBrand: function eventPassthrough(newValue, oldValue) {
      this.setCustomCss(newValue, oldValue);
    },
    'broadcast.eventPassthrough': function eventPassthrough(newValue, oldValue) {
      if (JSON.stringify(newValue?.brand) !== JSON.stringify(oldValue?.brand)) {
        if (newValue.brand === undefined) {
          this.currentBrand = this.broadcast.encoderPassthrough?.brand;
          this.currentBrandReference = 'encoder';
        } else {
          this.currentBrand = newValue.brand;
          this.currentBrandReference = 'event';
        }
      }
    },
    'broadcast.encoderPassthrough': function eventPassthrough(newValue, oldValue) {
      if (JSON.stringify(newValue?.brand) !== JSON.stringify(oldValue?.brand)) {
        if (!this.currentBrand || this.currentBrandReference === 'encoder') {
          this.currentBrand = newValue.brand;
          this.currentBrandReference = 'encoder';
        }
      }
    },
  },
};
export default currentBrandMixin;
