import { attr } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from './ApplicationRecord';

const Broadcast = ApplicationRecord.extend({
  static: {
    jsonapiType: 'broadcasts',
  },
  attrs: {
    room_name: attr({ persist: false }),
    name: attr({ persist: false }),
    expiresAt: attr({ persist: false }),
    createdAt: attr({ persist: false }),
    state: attr({ persist: false }),
    playbackUrl: attr({ persist: false }),
    playbackToken: attr({ persist: false }),
    thumbnailUrl: attr({ persist: false }),
    animationUrl: attr({ persist: false }),
    encoderPassthrough: attr({ persist: false }),
    eventPassthrough: attr({ persist: false }),
    flippers: attr({ persist: false }),
    flipperId: attr({ persist: false }),
    startAt: attr({ persist: false }),
    stopAt: attr({ persist: false }),
    clippedStart: attr({ persist: false }),
    clippedStop: attr({ persist: false }),
    connectionWindow: attr({ persist: false }),
    downloads: attr({ persist: false }),
    posterImageUrl: attr({ persist: false }),
    posterImageThumbnailUrl: attr({ persist: false }),
    posterImage720Url: attr({ persist: false }),
    posterImage1080Url: attr({ persist: false }),
    shareId: attr({ persist: false }),
    onlineCondolenceUrl: attr({ persist: false }),
    storyboardImageUrl: attr({ persist: false }),
    storyboardVttUrl: attr({ persist: false }),
    storyboardJsonUrl: attr({ persist: false }),
  },
});

export default Broadcast;
