<template>
  <b-container fluid class="header--wrapper theme--bg mb-4">
    <b-container class="header">
      <div class="d-sm-flex justify-content-sm-between align-items-center">
        <a v-if="logo && url" :href="url" target="_blank">
          <img :src="logo" class="logo" :alt="name"/>
        </a>
        <img v-else-if="logo" :src="logo" class="logo" :alt="name"/>
        <a
          v-if="onlineCondolenceUrl"
          :href="onlineCondolenceUrl"
          target="_blank"
          class="btn btn-sm btn-outline-dark"
        >Online Condoleren</a>
      </div>
    </b-container>
  </b-container>
</template>

<script>
import domainChecker from '../mixins/domainChecker';
import logoMemoriam from '../assets/images/logo-memoriam.png';
import logoAttend from '../assets/images/logo-attend-stream.png';

export default {
  mixins: [
    domainChecker,
  ],
  props: {
    broadcast: { type: Object, required: true },
    currentBrand: { type: Object, required: false },
  },
  computed: {
    onlineCondolenceUrl() {
      return this.broadcast?.onlineCondolenceUrl;
    },
    logo() {
      if (this.currentBrand?.logo) {
        return this.currentBrand?.logo;
      }
      if (this.attendDomain()) {
        return logoAttend;
      }
      return logoMemoriam;
    },
    name() {
      return this.currentBrand?.name;
    },
    url() {
      return this.currentBrand?.url;
    },
  },
};
</script>

<style>
.header--wrapper {
  background: #F1F1F1;
  /* min-height: 5rem; */
}
.header {
  padding: 1rem 0;
  text-align: left;
}

img.logo {
  max-height: 3rem;
}
@media only screen and (max-width: 576px) {
  .header {
    text-align: center;
  }
  img.logo {
    max-height: 2rem;
  }
}
</style>
