import Vue from 'vue';
import VueRouter from 'vue-router';
import ErrorMessage from '../views/ErrorMessage.vue';
import RedirectPage from '../views/RedirectPage.vue';
import LookupPage from '../views/LookupPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/redirect/:redirectId',
    name: 'RedirectPage',
    meta: {
      loaded: true,
    },
    component: RedirectPage,
  },
  {
    path: '/embed/:shareId',
    name: 'EmbedPage',
    meta: {
      loaded: true,
    },
    component: () => import(/* webpackChunkName: "EmbedPage" */ '../views/EmbedPage.vue'),
  },
  {
    path: '/download/:shareId',
    name: 'DownloadPage',
    meta: {
      loaded: true,
    },
    component: () => import(/* webpackChunkName: "DownloadPage" */ '../views/DownloadPage.vue'),
  },
  {
    path: '/teststream',
    name: 'TestPage',
    meta: {
      loaded: true,
    },
    component: () => import(/* webpackChunkName: "TestPage" */ '../views/TestPage.vue'),
  },
  {
    path: '/:shareId/teststream',
    name: 'EventTestPage',
    meta: {
      loaded: true,
    },
    component: () => import(/* webpackChunkName: "TestPage" */ '../views/TestPage.vue'),
  },
  {
    path: '/:shareId',
    name: 'PlayerPage',
    meta: {
      loaded: true,
    },
    component: () => import(/* webpackChunkName: "PlayerPage" */ '../views/PlayerPage.vue'),
  },
  {
    path: '/',
    name: 'LookupPage',
    meta: {
      loaded: true,
    },
    component: LookupPage,
  },
  {
    path: '*',
    name: 'ErrorMessage',
    meta: {
      loaded: true,
    },
    component: ErrorMessage,
    props: {
      invalidUrl: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
