<template>
  <section id="app">
    <div v-show="!loaded">
      Een moment geduld alstublieft, de pagina wordt geladen.
    </div>
    <router-view/>
  </section>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'App',
  data() {
    return {
      playerId: null,
    };
  },
  created() {
    this.playerId = this.$cookies.get('playerId');
    if (!this.playerId) {
      this.playerId = `memoriamtv-${uuidv4()}`;
      this.$cookies.set('playerId', this.playerId, Infinity);
    }
  },
  computed: {
    loaded() { return this.$route.meta.loaded; },
  },
};
</script>

<style>
#app {
  font-family: Gilroy, Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #33124C;
}
</style>
