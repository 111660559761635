import Vue from 'vue';
import {
  LayoutPlugin, ButtonPlugin, FormPlugin, ButtonToolbarPlugin, FormSelectPlugin,
} from 'bootstrap-vue';
import VueCookies from 'vue-cookies';
import VueGtag from 'vue-gtag';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Appsignal from '@appsignal/javascript';
import { errorHandler } from '@appsignal/vue';

import router from './router';
import App from './App.vue';

// Install BootstrapVue
Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormPlugin);
Vue.use(ButtonToolbarPlugin);
Vue.use(FormSelectPlugin);

Vue.use(VueCookies);

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    params: {
      anonymize_ip: true,
      send_page_view: true,
    },
  },
});

Vue.config.productionTip = false;

const appsignal = new Appsignal({
  key: process.env.VUE_APP_APPSIGNAL_API_KEY,
  environment: process.env.VUE_APP_APPSIGNAL_APP_ENV || process.env.VUE_APP_DEPLOY_ENV,
});

Vue.config.errorHandler = errorHandler(appsignal, Vue);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
