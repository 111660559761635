<template>
  <article v-if="!isErrorA404">
    <h1 class="text-center mt-4 mb-4">
      Er is iets fout gegaan.....
    </h1>
    <p>
      Probeer het nog een keer door de pagina te verversen
    </p>
  </article>
  <article v-else>
    <h1 v-if="title" class="text-center mt-4 mb-4">{{ title }}</h1>
    <b-row class="justify-content-center">
      <b-col cols="10" md="6" class="text-left">
        <p>Dit kan verschillende oorzaken hebben:</p>
        <dl>
          <dt>U heeft de link niet correct overgetypt.</dt>
          <dd>
            Vraag iemand om te controleren of de link correct is overgetypt en
            probeer het nog een keer.
          </dd>
          <dt>De link die u heeft gekregen werkt niet (meer).</dt>
          <dd v-if="memoriamDomain()">
            Dat kan gebeuren. Vraag de uitvaartverzorger of betrokkene om een
            correcte link op te sturen.
          </dd>
          <dd v-else>
            Dat kan gebeuren. Wellicht is er een nieuwe link beschikbaar.
          </dd>
          <dt v-if="memoriamDomain()">U heeft een code gekregen van 6 karakters</dt>
          <dd v-if="memoriamDomain()">
            Dan zit u op de verkeerde pagina. U dient naar de pagina
            van de uitvaartverzorger te gaan.
          </dd>
          <dt>Als dat niet helpt:</dt>
          <dd>
            <ul>
              <li>Probeer het op <a href="/">deze pagina</a></li>
              <li>Vraag het eens aan iemand anders.</li>
              <li>
                Heeft de persoon waarvan u de link heeft gekregen misschien
                een betere versie?
              </li>
            </ul>
          </dd>
          <dd />
          <dt v-if="memoriamDomain()">Als laatste redmiddel:</dt>
          <dd v-if="memoriamDomain()">
            Vul <a href="https://www.memoriam.tv/contact/">dit formulier</a>
            in. Helaas kunnen we geen snelle reactie garanderen.
          </dd>
        </dl>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import domainChecker from '../mixins/domainChecker';

export default {
  mixins: [domainChecker],
  props: {
    title: { Type: String, default: 'Er is geen uitzending op deze pagina.' },
    error: { Type: Object },
    invalidUrl: { Type: Boolean, default: false },
  },
  computed: {
    isErrorA404() {
      if (this.invalidUrl) {
        return true;
      }
      return (this.error && this.error.message === 'record not found');
    },
  },
};
</script>
